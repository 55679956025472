import { Component, Input, NgModule, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseDirective } from '../../../clientCommon/directives/BaseDirective';
import { ServiceHelperService } from '../../../clientCommon/services/serviceHelper.service';
import { BaseComponent } from '../../../clientCommon/components/BaseComponent';
import { UxHelper } from '../../../clientCommon/helper/uxHelper';
import { User } from '../../../common/models/user/user';
import { Router } from '@angular/router';
import { LogUtils } from '../../../common/utils/logUtils';
import { SpyIdHelper } from '../../../clientCommon/helper/spyIdHelper';
import { CommonModule } from '@angular/common';
import { MemberPeopleSearchComponentModule } from './peopleSearch';
import { UxTemplateModule } from 'app/components/uxTemplate.component';
import { NoteService } from '@clientCommon/services/note.service';
import { CommerceOrder } from '@common/models/commerce/commerceOrder';
import { CommerceContent } from '@common/models/commerce/commerceContent';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-member-header',
  templateUrl: 'memberHeader.component.html',
  styleUrls: ['./memberHeader.component.scss'],
})
export class MemberHeaderComponent extends BaseDirective implements OnInit, OnDestroy {
  @ViewChild('comment') relativeName:ElementRef;
  @Input() uxHelper: UxHelper;
  @Input() showSearchToggle: boolean = true;
  @Input() page: string = '';
  @Input() pageType: string = '';
  @Input() pageCategory: string = '';
  user: User;
  billingCycle = '';

  contentUpdatedCount = null;
  showSearchModal: boolean = false;
  idOfferClaimedOrDeclined: boolean = false;
  idProtectionContentId: string;
  unreadAlerts: number = 0;

  // ID Theft Protection Popup
  idProtectionEnabled = false;
  idProtectionPopupModel: boolean = true;

  // NPS Popup
  npsProcess = false;
  npsPopupModelEnabled: boolean = false; 
  npsPopupDisabled: boolean = true;   // session check
  npsTitle = 'What could we improve to serve you better?'; // 'Which features do you like the most?';
  npsScore = 0;

  spyIdHelper = new SpyIdHelper();

  initDone = false;
  
  private commerceStorageSubscription: Subscription;

  constructor(serviceHelperService: ServiceHelperService, public noteService: NoteService, activatedRoute: ActivatedRoute, private router: Router) {
    super(serviceHelperService, activatedRoute);
  }

  async ngOnInit() {
    try {
      await super.baseInit();

      this.user = this.serviceHelperService.authenticationService.getUser();
  
      this.initIdProtentionEnabled();
      
      await this.initCountContent();
  
      await this.initNpsPopup();
    } catch (e) {
      LogUtils.error('memberHeaderComponent.ngOnInit', e);
    } finally {
      this.initDone = true;
    }
  }

  ngOnDestroy(): void {
    try {
      this.commerceStorageSubscription.unsubscribe();
    } catch (e) {
      LogUtils.warn('memberHeaderComponent.ngOnDestroy', e);
    }
  }

  async initCountContent() {
    try {
      const count = await this.serviceHelperService.commerceService.countContentUpdated();

      this.contentUpdatedCount = count;
    } catch (e) {
      LogUtils.error('memberHeaderComponent.initCountContent', e);
    }
  }

  initIdProtentionEnabled() {
    try {
      this.idProtectionEnabled = this.uxHelper.getValue("comp.member.idProtection.enable") === 'true'? true: false;  // true;     

      if (this.idProtectionEnabled) {
        this.setupMonitoringAndAlertsInfo();
      }
  
      this.processCommerceStorage();  
    } catch (e) {
      LogUtils.error('memberHeaderComponent.initIdProtentionEnabled', e);
    }   
  }

  async initNpsPopup() {
    try {
      const commerceOrders: CommerceOrder[] = await this.serviceHelperService.commerceService.findCommerceOrders(true);

      // if nps popup is enable
      let isNpsVisible = this.uxHelper.getValue("comp.nps.popup.show.enabled") === 'true' ? true : false;
      let isNpsLanding = this.uxHelper.getValue("comp.nps.popup.show.page.landing") === 'true' ? true : false;
      let isNpsAccount = this.uxHelper.getValue("comp.nps.popup.show.page.profile") === 'true' ? true : false;
      let isNpsDetail = this.uxHelper.getValue("comp.nps.popup.show.page.detail") === 'true' ? true : false;
      let isNpsSpyDashboard = this.uxHelper.getValue("comp.nps.popup.show.page.detail") === 'true' ? true : false;
      let npsEligibility = this.uxHelper.getValue("comp.nps.popup.show.eligibility");

      // (this.uxHelper.pageType, this.uxHelper.page);
      if (isNpsVisible &&
        ((this.uxHelper.pageType === 'member' && this.uxHelper.page === 'landing' && isNpsLanding) ||
          (this.uxHelper.pageType === 'member' && this.uxHelper.page === 'profile' && isNpsAccount) ||
          (this.uxHelper.pageType === 'member' && this.uxHelper.page === 'detail' && isNpsDetail) ||
          (this.uxHelper.pageType === 'member' && this.uxHelper.page === 'spy-dashboard' && isNpsSpyDashboard)
        )) {
        this.noteService
          .findFeedbackNote(this.user._id, this.user.brandId)
          .then((notes) => {
            if (notes.length > 0) {
              // nps popup diable
              // check after 1month
              let note = notes[notes.length - 1];  // last node
              // createdTimestamp < 1 month
              if (this.npsCheckOneMonth(note.createdTimestamp)) {
                this.npsPopupModelEnabled = true;
                if (commerceOrders.length > 0) {
                  // price == 0
                  const price = commerceOrders[0]?.commerceOfferDetail?.price?.amount || 0;
                  // (price, commerceOrders[0].commerceOfferDetail.price);
                  if (price == 0 && commerceOrders[0].sequence == 0) {
                    this.billingCycle = 'Free trial';
                  } else {
                    this.billingCycle = 'S' + commerceOrders[0].sequence;
                  }
                }
              } else {
                this.npsPopupModelEnabled = false;
              }
            } else {
              // New NPS popup            
              if (commerceOrders.length > 0) {
                // price == 0
                const price = commerceOrders[0]?.commerceOfferDetail?.price?.amount || 0;
                // (price, commerceOrders[0].commerceOfferDetail.price);
                if (price == 0 && commerceOrders[0].sequence == 0) {
                  this.billingCycle = 'Free trial';
                } else {
                  this.billingCycle = 'S' + commerceOrders[0].sequence;
                }
                LogUtils.debug("Eligibility, processed count => ", npsEligibility, commerceOrders[0].processed, price);
                if (npsEligibility) {
                  if (commerceOrders[0].processed >= npsEligibility) {
                    this.npsPopupModelEnabled = true;
                  } else {
                    this.npsPopupModelEnabled = false;
                  }
                }
              }
            }
          }).catch((e) => {
            //this.npsPopupModelEnabled = true; // no data
          });
      } else {
        this.npsPopupModelEnabled = false;
      }
    } catch (e) {
      LogUtils.error('memberHeaderComponent.initNpsPopup', e);
    }
  }

  npsCheckOneMonth(createdTimestamp: number): boolean {
    const now = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(now.getMonth() - 1);
    return createdTimestamp < oneMonthAgo.getTime();
  }

  processCommerceStorage() {
    this.commerceStorageSubscription = this.serviceHelperService.commerceService.getStorageAsObservable().subscribe((commerceStorage) => {
      if (!this.spyIdHelper.isIdProtectionEnabled(commerceStorage)) {
        return;
      }      
      this.unreadAlerts = commerceStorage?.unreadAlerts || 0;
      if (commerceStorage?.idProtectionContentId) {
        this.idOfferClaimedOrDeclined =
          commerceStorage?.reportClaimedContentId || commerceStorage?.idClaimBannerRejected ? true : false;
        
        this.idProtectionPopupModel = commerceStorage?.idClaimPopupRejected ? true : false;

        this.idProtectionContentId = commerceStorage?.idProtectionContentId;
        this.npsPopupDisabled = commerceStorage?.npsPopupDisabled ? true : false;
      } else {
        this.idProtectionPopupModel = true;
      }
    });
  }

  setupMonitoringAndAlertsInfo() {

    const commerceStorage = this.serviceHelperService.commerceService.getStorage()
    //const spyDwbCommerceContentId = commerceStorage?.claimedSpyDwbCommerceContentId;
    const idProtectionCommerceContentId = commerceStorage?.idProtectionContentId;
    const claimedSpyDwbCommerceContentIds = commerceStorage?.claimedSpyDwbCommerceContentIds;

    LogUtils.debug('setupMonitoringAndAlertsInfo => ', commerceStorage, claimedSpyDwbCommerceContentIds, idProtectionCommerceContentId);

    if(!claimedSpyDwbCommerceContentIds) { return; }
    let unreadAlerts = 0;

    for(const spyDwbCommerceContentId of claimedSpyDwbCommerceContentIds) {
      this.serviceHelperService.commerceService.findContent(spyDwbCommerceContentId)
      .then((commerceContent: CommerceContent ) => {
        unreadAlerts = unreadAlerts + commerceContent?.content?.monitored_data?.unreadAlerts || 0
        this.serviceHelperService.commerceService.updateStorage({
          ...commerceStorage,
          unreadAlerts: unreadAlerts
        })
      })
      .catch((e) => {
        LogUtils.error('error in getting commerceContent', e)
        this.serviceHelperService.alertService.error("Error");
      });
    }

    if(!idProtectionCommerceContentId) { return; }
    this.serviceHelperService.commerceService.findContent(idProtectionCommerceContentId)
    .then((commerceContent: CommerceContent ) => {
      unreadAlerts = unreadAlerts + commerceContent?.content?.monitored_data?.unreadAlerts || 0
      this.serviceHelperService.commerceService.updateStorage({
        ...commerceStorage,
        unreadAlerts: unreadAlerts
      })
    })
    .catch((e) => {
      LogUtils.error('error in getting commerceContent', e)
      this.serviceHelperService.alertService.error("Error");
    });    
  }

  shouldShowRhs() {
    let flag = true;
    if (
      this.uxHelper.pageType === BaseComponent.PAGE_TYPE.sales ||
      this.uxHelper.page === BaseComponent.PAGE.forgotPassword
    ) {
      flag = false;
    }
    return flag;
  }

  onSearchToggleBtn() {
    this.showSearchModal = !this.showSearchModal;

    // Temporary comment because it is blocking scrolling pages all over the website because of not setting auto automatically
    if (this.showSearchModal) {
      // document.body.style.overflow = 'hidden';
    } else {
      // document.body.style.overflow = 'auto';
    }
  }

  handleClick(url) {
    this.callDeclineIdProtectionOfferBanner();
    this.router.navigateByUrl(url);
  }

  handleMoveClick(url) {
    this.handlePopupClose();
    this.router.navigateByUrl(url);
  }
  handlePopupClick(e) {
    this.handlePopupClose();
  }

  // Sticky Header Popup
  callDeclineIdProtectionOfferBanner() {
    this.serviceHelperService.commerceService.declineIdProtectionOfferBanner(this.idProtectionContentId);
  }

  // ID Theft Protection Popup
  handlePopupClose() {
    this.idProtectionPopupModel = true;
    // If the user clicks on the X option the modal should be closed and must not be shown to the user again
    this.serviceHelperService.commerceService.declineIdProtectionOfferPopup(this.idProtectionContentId);
  }

  // NPS number click
  npsNumberClick(num) {
    this.npsScore = num;
    this.npsProcess = true;
    this.npsPopupModelEnabled = false;
    if (num < 9) {
      this.npsTitle = 'How can we improve your experience?';
    }else {
      this.npsTitle = 'What could we improve to serve you better?'; // 'Which features do you like the most?';
    }
  }

  handleFeedbackClick(feedback) {
    var param = {
      privateFlag: true,
      note: feedback,
      userId: this.user._id,
      type: 'nps-feedback',
      brandId: this.user.brandId,
      data: {
        page: this.uxHelper.page, // BaseComponent.PAGE.detail,
        pageType: BaseComponent.PAGE_TYPE.member,
        user_id: this.user._id,
        brand_name: this.user.brandId,
        nps_score: this.npsScore,
        feedback: feedback,
        billing_cycle: this.billingCycle
      }
    }

    LogUtils.debug("feddback Note :", param);
    return this.noteService
        .feedbackNote(param)
        .then(() => {
          this.npsProcess = false;
          this.npsPopupModelEnabled = false;

          this.serviceHelperService.trackingService.report({
            page: this.uxHelper.page, // BaseComponent.PAGE.detail,
            pageType: BaseComponent.PAGE_TYPE.member,
            type: 'nps-feedback',
            action: 'submit',
            customerId: this.user._id,
            score: this.npsScore,
            sequence: this.billingCycle
          });

        })
        .catch((err) => {
          LogUtils.error(err);
        });
  }

  handleNpsPopupClose() {

    const commerceStorage = this.serviceHelperService.commerceService.getStorage();
    this.serviceHelperService.commerceService.updateStorage({
      ...commerceStorage,
      npsPopupDisabled: true
    });

    var param = {
      privateFlag: true,
      note: '',
      userId: this.user._id,
      type: 'feedback',
      brandId: this.user.brandId,
      data: {}
    }
    return this.noteService
        .feedbackNote(param)
        .then(() => {
          this.npsProcess = false;
          this.npsPopupModelEnabled = false;
        })
        .catch((err) => {
          LogUtils.error(err);
        });
  }

}

@NgModule({
  declarations: [MemberHeaderComponent],
  imports: [CommonModule, MemberPeopleSearchComponentModule, UxTemplateModule],
  exports: [MemberHeaderComponent],
})
export class MemberHeaderComponentModule {}