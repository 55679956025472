import { CommerceContent } from "../../../common/models/commerce/commerceContent";
import { peopleSearchProductKeys } from "../../../common/custom/peopleSearch/peopleSearchProductKeys";
import { customClientPaths } from "../../../common/custom/customPathHelpers";

export class LandingPeopleSearchMemberUtils {
  hasTitle(commerceContent: CommerceContent) {
    if (
      commerceContent &&
      commerceContent.tempClient &&
      commerceContent.tempClient.raw &&
      commerceContent.tempClient.raw.person &&
      commerceContent.tempClient.raw.person[0]
    ) {
      return true;
    }

    return false;
  }

  getTitleByCommerceContent(commerceContent: CommerceContent) {
    if (this.hasTitle(commerceContent)) {
      let title = this.getShortTitleByCommerceContent(commerceContent);
      let subTitle = this.getSubTitleByCommerceContent(commerceContent);
      if (subTitle) {
        title += ", " + subTitle;
      }
      return title;
    }
  }

  getShortTitleByCommerceContent(commerceContent: CommerceContent) {
    if (this.hasTitle(commerceContent)) {
      if (commerceContent.productKey === peopleSearchProductKeys.nameSearch) {
        return commerceContent.tempClient.raw.person[0].fullName;
      } else if (
        commerceContent.productKey === peopleSearchProductKeys.phoneSearch
      ) {
        return commerceContent.content[0].contentInfo.searchContent[0]
          .contentInfo.phone;
      }
    }
  }

  getShortLocationByCommerceContent(commerceContent: CommerceContent) {
    if (this.hasTitle(commerceContent)) {
      if (commerceContent.productKey === peopleSearchProductKeys.nameSearch) {
        return (
          commerceContent.tempClient.raw.person[0].address?.city +
          ", " +
          commerceContent.tempClient.raw.person[0].address?.county
        );
      } else if (
        commerceContent.productKey === peopleSearchProductKeys.phoneSearch
      ) {
        return commerceContent.content[0].contentInfo.searchContent[0]
          .contentInfo?.cityState;
      }
    }
  }

  getCreatedDateByCommerceContent(commerceContent: CommerceContent) {
    if (this.hasTitle(commerceContent)) {
      if (commerceContent.productKey === peopleSearchProductKeys.nameSearch) {
        return commerceContent.createdTimestamp;
      }
    }
  }

  getSubTitleByCommerceContent(commerceContent: CommerceContent) {
    if (this.hasTitle(commerceContent)) {
      let subtitle = "";
      if (commerceContent.productKey === peopleSearchProductKeys.nameSearch) {
        if (
          commerceContent.tempClient.raw.person[0].cityStates &&
          commerceContent.tempClient.raw.person[0].cityStates.length
        ) {
          subtitle = commerceContent.tempClient.raw.person[0].cityStates[0];
        }
      } else if (
        commerceContent.productKey === peopleSearchProductKeys.phoneSearch
      ) {
      }
      return subtitle;
    }
  }

  getContentUrl(commerceContent: CommerceContent) {
    let url = "/";

    if (commerceContent.productKey === peopleSearchProductKeys.nameSearch) {
      url += customClientPaths.memberNameSearchDetail;
    } else if (
      commerceContent.productKey === peopleSearchProductKeys.phoneSearch
    ) {
      url += customClientPaths.memberPhoneSearchDetail;
    }

    url += "/" + commerceContent._id + "/linear";

    return url;
  }

  getAgeByCommerceContent(commerceContent: CommerceContent) {
    if (this.hasTitle(commerceContent)) {
      return commerceContent.tempClient.raw.person[0].age || 'N/A';
    }
    return 'N/A';
  }
}

export const landingPeopleSearchMemberUtils = new LandingPeopleSearchMemberUtils();
