import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgModule, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { phoneUtils } from '../../../../../../common/utils/phoneUtils';
import { AddressField, AddressUtils } from '../../../../../../common/utils/addressUtils';
import { customClientPaths } from '../../../../../../common/custom/customPathHelpers';
import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { redirectHelper } from '../../../../../../clientCommon/helper/redirectHelper';
import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
import { environment } from '../../../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { MemberUpsellPremiumBadgeComponentModule } from '@member/components/upsells/premium-badge';
import { MiniReportHeaderModule } from '@member/peopleSearch/components/mini/header/header.component';
import { MemberUpsellAssociatesComponentModule } from '@member/components/upsells/associates';
import { MiniButtonComponentModule } from '@member/peopleSearch/components/mini/button/button.component';
import { ReportActionComponentModule } from '../reportAction/reportAction.component';
import { AdComponentModule } from 'app/components/ad/ad.component';
import { MiniReportNotesComponentModule } from '@member/peopleSearch/components/mini/notes/notes.component';
import { MiniReportAdProtectionComponentModule } from '@member/peopleSearch/components/mini/ad/ad-protection.component';
import { FactFindersComponentModule } from '../factFinders/factFinders.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { UxTemplateModule } from 'app/components/uxTemplate.component';
import { DirectionButtonComponentModule } from '../directionButton/directionButton.component';
import { stringUtils } from '../../../../../../common/utils/stringUtils';

declare var $: any;

class CustomProduct {
  public static ACTION = {
    redirect: 'redirect',
  };

  productType: string;
  name: string;
  action: string;
  value: string;
  forms: {
    [type: string]: any;
  }[] = [];

  public static CODE = {
    requestState: 'requestState',
  };
}

@Component({
  selector: 'app-mini-miscellaneous',
  templateUrl: 'miniMiscellaneous.component.html',
  styleUrls: ['miniMiscellaneous.component.scss'],
})
export class MiniMiscellaneousComponent extends PersonDetail implements OnInit, OnDestroy, AfterViewInit {
  href = '';

  @Output() showLoader = new EventEmitter<void>();
  @Input() isPdfPrintView: boolean = false;

  @ViewChild('scrollToTop', { static: true })
  scrollToTopBtnElement: ElementRef;
  
  phoneUtils = phoneUtils;
  stringUtils = stringUtils;
  addressField: AddressField = AddressUtils.getAddressField('US');
  customerNumber = '';
  loaderEyeTimeout: number = environment.miniDetailsLoaderEyeTimeout; //2000; // loader animated Eye Timeout

  stateList = this.addressField;
  ageList = [];
  model: any = {
    fname: '',
    mname: '',
    lname: '',
    city: '',
    state: 'all',
    // age: "",
    phone: '',
    email: '',
    // relativeFName: "",
    // relativeLName: ""
  };
  relativesList = [];

  allStateObj = [{ code: 'all', name: 'All States' }];
  addressFieldWithAllStateOption;
  customProducts: CustomProduct[] = [];
  selectedCustomProductValues: any = {};
  productType: string;
  selectedPhotos = [];
  swiperIndex = 0;
  showAllRelatives = false;

  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    private router: Router,
    public renderer: Renderer2,
    public toastr: ToastrService,
    public authService: AuthenticationService,
    public noteService: NoteService
  ) {
    super(serviceHelperService, activatedRoute, toastr, noteService, authService);
  }

  ngOnInit() {
    this.customerNumber = this.uxHelper.getValue('comp.brand.customer.phone');
    this.showAllRelatives = this.isPdfPrintView;
    // creatting state list with all state option
    this.addressFieldWithAllStateOption = [
      ...this.allStateObj,
      ...this.addressField.getAdministrativeAreaOptionsArray(),
    ];
    if (!this.model.state) {
      this.model.state = 'all';
    }
    // creating ageList for dropdown
    let g = 18;
    while (g > 17 && g < 60) {
      this.ageList.push(g);
      g++;
    }

    this.href = this.router.url;
    this.relativesList = [...this.person.associates];

    return this.init().then(() => {
      this.serviceHelperService.trackingService.report({
        page: BaseComponent.PAGE.detail,
        pageType: BaseComponent.PAGE_TYPE.member,
        type: peopleSearchProductKeys.nameSearch,
        section: 'misc',
        action: 'view',
      });
      
      this.serviceHelperService.trackingService.report({
        page: BaseComponent.PAGE.detail,
        pageType: BaseComponent.PAGE_TYPE.member,
        type: peopleSearchProductKeys.factFinder,
        action: 'factFinderViewed',
      });

      this.changeDetectorRef.detectChanges();
    });
  }
  ngAfterViewInit() {
    // Register scroll to top event listener.
    const screenHeight = window.screen.availHeight;
    window.onscroll = () => {
      if (document.body.scrollTop > screenHeight || document.documentElement.scrollTop > screenHeight) {
        this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'flex');
      } else {
        this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'none');
      }
    };
  }
  scrollToTopHandler() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  ngOnDestroy() {
    return super.onDestroy();
  }

  init() {
    // Tooltip init
    setTimeout(() => {
      $('.tooltip-container').tooltip({
        html: true,
        trigger: 'click',
      });
    }, 0);
    return super.init().then(() => {
      this.customerNumber = this.uxHelper.getValue('comp.brand.customer.phone');
    });
  }

  handlePrevious() {
    window.scrollTo(0, 0);
    this.showLoader.emit();
    setTimeout(() => {
      this.router.navigate([`member/nameSearch/detail/${this.commerceContent._id}/criminalRecords`]);
    }, this.loaderEyeTimeout);
  }

  onContactUs() {
    window.scrollTo(0, 0);
    // this.goTo.emit();
    this.router.navigate(['member/mini-contact-us']);
  }

  cancelModal(index) {
    $('#modal-' + index).modal('hide');
  }

  findOptionByKey(options, key) {
    let chosenOption;
    if (options) {
      options.some((option) => {
        if (option.key === key) {
          chosenOption = option;
          return true;
        }
      });
    }

    return chosenOption;
  }

  findCustomProduct(productType) {
    let foundCustomProduct: CustomProduct = null;
    this.customProducts.some((customProduct) => {
      if (customProduct.productType === productType) {
        foundCustomProduct = customProduct;
        return true;
      }
    });

    if (foundCustomProduct) {
      if (!this.selectedCustomProductValues[productType]) {
        this.selectedCustomProductValues[productType] = {};
      }

      if (foundCustomProduct.forms) {
        foundCustomProduct.forms.forEach((form) => {
          if (form.name && form.default && this.selectedCustomProductValues[productType][form.name] === undefined) {
            let value = form.default;
            if (value === CustomProduct.CODE.requestState) {
              value = this.uxComposite.requestState;
            }
            this.selectedCustomProductValues[productType][form.name] = value;
            let option = this.findOptionByKey(form.options, value);
            if (option) {
              foundCustomProduct.value = option.url;
            }
          }
        });
      }
    }

    return foundCustomProduct;
  }

  onSearchAgain() {
    // copied from memberPeopleSearch.component.ts
    this.productType = peopleSearchProductKeys.nameSearch;
    let url = '/' + customClientPaths.memberNameSearchLoader;
    this.serviceHelperService.peopleSearchService.clear();
    let customProduct = this.findCustomProduct(this.productType);
    if (customProduct) {
      if (customProduct.action === CustomProduct.ACTION.redirect) {
        url = customProduct.value;
      }
    }
    this.serviceHelperService.userInputService.userInput = {
      ...this.model,
    };
    this.serviceHelperService.userInputService.storeUserInput();
    return redirectHelper.redirect(this.serviceHelperService, url);
  }

  formatPhone(event) {
    event.target.value = this.model.number = this.phoneUtils.formatPhoneNumber(event.target.value);
  }

  openPhotoModal(photos) {
    this.selectedPhotos = photos;
    this.swiperIndex = 0;
    if (this.selectedPhotos?.length < 2) {
      return;
    }
    this.serviceHelperService.trackingService.report({
      page: BaseComponent.PAGE.detail,
      pageType: BaseComponent.PAGE_TYPE.member,
      type: peopleSearchProductKeys.nameSearch,
      section: 'associates',
      action: 'view_more_images',
    });
    $('#personPhotoModal').modal({
      backdrop: 'static',
    });
  }

  moveStep(step) {
    if (step < 0) {
      if (this.swiperIndex === 0) {
        this.swiperIndex = this.selectedPhotos.length - 1;
      } else {
        this.swiperIndex--;
      }
    } else {
      if (this.swiperIndex === this.selectedPhotos.length - 1) {
        this.swiperIndex = 0;
      } else {
        this.swiperIndex++;
      }
    }
  }

  switchShowAllRelative() {
    this.showAllRelatives = !this.showAllRelatives;
  }

  get factFindersEnabled() {
    return this.uxHelper.uxComposite.getUxcomp('comp.member.factFinders.enabled') || false;
  }
}

@NgModule({
  declarations: [MiniMiscellaneousComponent],
  imports: [
    CommonModule,
    FormsModule,

    NgSelectModule,

    MemberUpsellPremiumBadgeComponentModule,
    MemberUpsellAssociatesComponentModule,
    
    MiniReportHeaderModule,
    MiniButtonComponentModule,
    MiniReportNotesComponentModule,
    ReportActionComponentModule,
    AdComponentModule,
    FactFindersComponentModule,
    DirectionButtonComponentModule,
    MiniReportAdProtectionComponentModule,
    UxTemplateModule,
  ],
  exports: [MiniMiscellaneousComponent],
})
export class MiniMiscellaneousComponentModule {}
