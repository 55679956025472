import { Component, OnInit, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { UxComposite } from '../../../../../../common/models/ux/uxComposite';
import { CommonModule } from '@angular/common';
import {
  MiniButtonComponent,
  MiniButtonComponentModule,
} from '@member/peopleSearch/components/mini/button/button.component';
import { AddNoteComponentModule } from './addNote/addNote.component';
import { SaveReportComponentModule } from './saveReport/saveReport.component';
import { GetAlertsComponentModule } from './getAlerts/getAlerts.component';
import { TutorialModalComponentModule } from '../tutorialModal/tutorialModal.component';

@Component({
  selector: 'app-report-action',
  templateUrl: 'reportAction.component.html',
  styleUrls: ['reportAction.component.scss'],
})
export class ReportActionComponent implements OnInit {
  @Input() downloadPath: string;
  @Input() commerceContent: any;
  @Input() asFlexRow: boolean = false;
  @Input() uxComposite: UxComposite;
  @Input() extId: string;
  @Input() csrPhone: string;
  @Input() disableMonitoring = false;
  
  @Output() setMonitoring = new EventEmitter<any>();
  @Output() updateNotes = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  addNote() {}

  handleSetMonitoring(val) {
    this.setMonitoring.emit(val);
  }

  handleUpdateNotes() {
    this.updateNotes.emit();
  }
}

@NgModule({
  declarations: [ReportActionComponent],
  imports: [
    CommonModule,

    MiniButtonComponentModule,
    AddNoteComponentModule,
    SaveReportComponentModule,
    GetAlertsComponentModule,
    TutorialModalComponentModule,
  ],
  exports: [ReportActionComponent],
})
export class ReportActionComponentModule {}
