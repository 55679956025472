import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  Input,
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  AfterViewInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceHelperService } from '../../../../../../clientCommon/services/serviceHelper.service';
import { PersonDetail } from '../../../personDetail';
import { ToastrService } from '../../../../../service/toastr.service';
import { BaseComponent } from '../../../../../../clientCommon/components/BaseComponent';
import { peopleSearchProductKeys } from '../../../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { AuthenticationService, NoteService } from '../../../../../../clientCommon/services';
import { CommonModule } from '@angular/common';
import { SocialCardComponentModule } from './socialCard/socialCard.component';
import { MemberUpsellPremiumBadgeComponentModule } from '@member/components/upsells/premium-badge/premium-badge.component';
import { MiniReportHeaderModule } from '@member/peopleSearch/components/mini/header/header.component';
import { MemberUpsellGlobalComponentModule } from '@member/components/upsells/global/upsell-global.component';
import { MemberUpsellHouseHoldComponentModule } from '@member/components/upsells/household/upsell-household.component';
import { MiniReportNotesComponentModule } from '@member/peopleSearch/components/mini/notes/notes.component';
import { AdComponentModule } from 'app/components/ad/ad.component';
import { MiniReportFooterModule } from '@member/peopleSearch/components/mini/footer/footer.component';
import { SearchedVersiumData } from '@common/custom/models/peopleSearch/searchedVersiumData';
import moment from 'moment';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperModule } from '../../../../../../clientCommon/directives/SwiperDirective';

declare var $: any;

@Component({
  selector: 'app-mini-social-media',
  templateUrl: 'miniSocialMedia.component.html',
  styleUrls: ['miniSocialMedia.component.scss'],
})
export class MiniSocialMediaComponent extends PersonDetail implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  href: string = '';
  scrollLeft: number = 0;
  @Input() isPdfPrintView: boolean = false;
  @Output() showLoader = new EventEmitter<void>();
  bonusflag = false;
  enhancedflag = false;

  public imageSliderConfig: SwiperOptions = {
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
      },
      600: {
        slidesPerView: 3,
      },
      475: {
        slidesPerView: 2,
      },
    },
  };

  socialArray: Array<any> = [];
  personPhotos: Array<any> = [];
  versiumContentRow: SearchedVersiumData;

  formatDob: any;

  @ViewChild('scrollToTop', { static: true })
  scrollToTopBtnElement: ElementRef;

  get effectiveSocials() {
    return this.socialArray.filter((e) => !e?.photos);
  }

  constructor(
    public serviceHelperService: ServiceHelperService,
    public changeDetectorRef: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    public renderer: Renderer2,
    private router: Router,
    public toastr: ToastrService,
    public noteService: NoteService,
    public authService: AuthenticationService
  ) {
    super(serviceHelperService, activatedRoute, toastr, noteService, authService);
  }
  ngAfterViewInit() {
    // Register scroll to top event listener.
    const screenHeight = window.screen.availHeight;
    window.onscroll = () => {
      if (document.body.scrollTop > screenHeight || document.documentElement.scrollTop > screenHeight) {
        this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'flex');
      } else {
        this.renderer.setStyle(this.scrollToTopBtnElement.nativeElement, 'display', 'none');
      }
    };
  }
  scrollToTopHandler() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  ngOnInit() {
    this.href = this.router.url;
    const upsellnameflag = this.uxHelper.uxComposite.get('comp.billing.enhanced.name.enabled');
    this.bonusflag = upsellnameflag.bonus;
    this.enhancedflag = upsellnameflag.enhanced;

    return this.init().then(() => {
      this.versiumContentRow = this.commerceContent?.getVersiumRaw()?.tempClient?.processed?.versium?.[0];
      if (this.versiumContentRow?.general?.dob) {
        try {
          if (this.versiumContentRow?.general?.dob.length === 6) {
            const part1 = this.versiumContentRow?.general?.dob.substring(0, 4);
            const part2 = this.versiumContentRow?.general?.dob.substring(4, 6);
            this.formatDob = part2+'/'+part1;
  
          }else {
            this.formatDob = 'N/A';
          }
          //this.formatDob = moment(this.versiumContentRow.general.dob, 'YYYYMM').format('MM/YYYY');
        }catch(e) {
          this.formatDob = 'N/A';
        }
      }

      this.socialArray = [...this.person.photos, ...this.person.profiles];
      let facebook = this.socialArray.some((el) => el.service === 'facebook');
      if (!facebook) {
        this.socialArray.unshift({
          profileUrl: '',
          service: 'facebook',
          url: '',
        });
      } else {
        let object = this.socialArray.filter((obj) => {
          return obj.service === 'facebook';
        });

        this.socialArray = this.socialArray.filter((el) => el.service !== 'facebook');

        this.socialArray.unshift(object[0]);
      }
      let linkedin = this.socialArray.some((el) => el.service === 'linkedin');
      if (!linkedin) {
        this.socialArray.unshift({
          profileUrl: '',
          service: 'linkedin',
          url: '',
        });
      } else {
        let object = this.socialArray.filter((obj) => {
          return obj.service === 'linkedin';
        });
        this.socialArray = this.socialArray.filter((el) => el.service !== 'linkedin');
        this.socialArray.unshift(object[0]);
      }
      let instagram = this.socialArray.some((el) => el.service === 'instagram');
      if (!instagram) {
        this.socialArray.unshift({
          profileUrl: '',
          service: 'instagram',
          url: '',
        });
      } else {
        let object = this.socialArray.filter((obj) => {
          return obj.service === 'instagram';
        });
        this.socialArray = this.socialArray.filter((el) => el.service !== 'instagram');
        this.socialArray.unshift(object[0]);
      }
      let twitter = this.socialArray.some((el) => el.service === 'twitter');
      if (!twitter) {
        this.socialArray.unshift({
          profileUrl: '',
          service: 'twitter',
          url: '',
        });
      } else {
        let object = this.socialArray.filter((obj) => {
          return obj.service === 'twitter';
        });
        this.socialArray = this.socialArray.filter((el) => el.service !== 'twitter');
        this.socialArray.unshift(object[0]);
      }

      this.serviceHelperService.trackingService.report({
        page: BaseComponent.PAGE.detail,
        pageType: BaseComponent.PAGE_TYPE.member,
        type: peopleSearchProductKeys.nameSearch,
        section: 'socialMedia',
        action: 'view',
      });
      this.changeDetectorRef.detectChanges();


    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  init() {
    // Tooltip init
    setTimeout(() => {
      $('.tooltip-container').tooltip({
        html: true,
        trigger: 'click',
      });
    }, 0);
    return super.init();
  }

  handleLoader() {
    this.showLoader.emit();
  }

  handleScrollRight() {
    // if (this.scrollLeft > this.person.photos.length * 300) {
    //   return;
    // }

    // this.scrollLeft += 300;
    const container = document.querySelector('.mini-reports-body-diagram__body');
    if (container.scrollLeft < this.person.photos.length * 300) {
      container.scrollLeft += 300;
    }
  }

  handleScrollLeft() {
    // this.scrollLeft -= 300;
    // if (this.scrollLeft < 0) {
    //   this.scrollLeft = 0;
    // }

    const container = document.querySelector('.mini-reports-body-diagram__body');
    if (container.scrollLeft > 0) {
      container.scrollLeft -= 300;
    }
  }

  handleScrollRightImage() {
    // if (this.scrollLeft > this.person.photos.length * 300) {
    //   return;
    // }

    // this.scrollLeft += 300;
    const container = document.querySelector('.mini-reports-body-social-images-wrapper');
    if (container.scrollLeft < this.person.photos.length * 300) {
      container.scrollLeft += 300;
    }
  }

  handleScrollLeftImage() {
    // this.scrollLeft -= 300;
    // if (this.scrollLeft < 0) {
    //   this.scrollLeft = 0;
    // }

    const container = document.querySelector('.mini-reports-body-social-images-wrapper');
    if (container.scrollLeft > 0) {
      container.scrollLeft -= 300;
    }
  }

  counter(i: number) {
    return new Array(i);
  }

  getNoOfKeysInObject(obj) {
    let x = 0;
    x = Object.keys(obj).length;
    return x;
  }
}

@NgModule({
  declarations: [MiniSocialMediaComponent],
  imports: [
    CommonModule,
    SocialCardComponentModule,
    MiniReportHeaderModule,
    MemberUpsellPremiumBadgeComponentModule,
    MemberUpsellGlobalComponentModule,
    MemberUpsellHouseHoldComponentModule,
    MiniReportNotesComponentModule,
    AdComponentModule,
    MiniReportFooterModule,
    SwiperModule,
  ],
  exports: [MiniSocialMediaComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class MiniSocialMediaComponentModule {}
