import { Component, Input, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ToastrService } from '../../../../service/toastr.service';
import { CommerceContent } from '../../../../../common/models/commerce/commerceContent';
import { BaseComponent } from '../../../../../clientCommon/components/BaseComponent';
import { ServiceHelperService } from '../../../../../clientCommon/services/serviceHelper.service';
import { LandingPeopleSearchMember } from '../../../peopleSearch/landingPeopleSearchMember';
import { landingPeopleSearchMemberUtils } from '../../../peopleSearch/landingPeopleSearchMemberUtils';
import { CommonModule } from '@angular/common';
import { MemberHeaderComponentModule } from '@member/components/memberHeader.component';
import { MemberPeopleSearchComponentModule } from '@member/components/peopleSearch';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgSelectModule } from '@ng-select/ng-select';
import { MemberFooterComponentModule } from '@member/components/memberFooter.component';
import { AddNoteComponentModule } from '@member/peopleSearch/nameSearch/detail/reportAction/addNote/addNote.component';
import { ButtonsModule } from '../../../../components/buttons/buttons.module';

@Component({
  selector: 'app-saved-reports-page',
  templateUrl: './savedReportsPage.component.html',
  styleUrls: ['./savedReportsPage.component.scss'],
})
export class SavedReportsPage extends LandingPeopleSearchMember implements OnInit {
  @Input() items: CommerceContent[];
  selectedItem;
  searchUtils = landingPeopleSearchMemberUtils;
  relativeOptions = [
    { name: 'Father', id: CommerceContent.RELATIONSHIP_CODE.father },
    { name: 'Mother', id: CommerceContent.RELATIONSHIP_CODE.mother },
    { name: 'Son', id: CommerceContent.RELATIONSHIP_CODE.son },
    { name: 'Daughter', id: CommerceContent.RELATIONSHIP_CODE.daughter },
    { name: 'Brother', id: CommerceContent.RELATIONSHIP_CODE.brother },
    { name: 'Sister', id: CommerceContent.RELATIONSHIP_CODE.sister },
    { name: 'Relative', id: CommerceContent.RELATIONSHIP_CODE.relative },
    { name: 'Business', id: CommerceContent.RELATIONSHIP_CODE.business },
    { name: 'Friend', id: CommerceContent.RELATIONSHIP_CODE.friend },
    { name: 'Neighbor', id: CommerceContent.RELATIONSHIP_CODE.neighbor },
    { name: 'Romantic', id: CommerceContent.RELATIONSHIP_CODE.romantic },
    { name: 'Other', id: CommerceContent.RELATIONSHIP_CODE.other },
  ];
  isPrivateNote: boolean = true;

  constructor(
    serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    private router: Router
  ) {
    super(serviceHelperService, activatedRoute);
    this.pageCategory = BaseComponent.PAGE_CATOGORY.nameSearch;
    this.onInit().then(() => {
      this.initDone = true;
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    return super.onDestroy();
  }

  onView(item) {}

  viewReports(url) {
    this.router.navigate([url]);
  }

  onSearchAgain() {}
  addNote(noteType = 'public') {
    this.isPrivateNote = noteType == 'public' ? false : true;
  }

  goToHomePage() {
    this.router.navigate([this.clientPaths.member]);
  }

  handleSetMonitoring(item: CommerceContent, flag: boolean) {
    item.setSubscribe(flag);
    return Promise.resolve().then(() => {
      if (item) {
        this.serviceHelperService.peopleSearchService
          .subscribe(item, flag)
          .then(() => {
            item.setSubscribe(flag);
          })
          .catch((e) => {
            this.toastr.error(e.rawResponse.message, 'Error');
            item.setSubscribe(!flag);
          });
      }
    });
  }

  changePage(increment: boolean = true) {
    if (increment) {
      this.commerceContentInfos[this.currentTab].page++;
    } else {
      this.commerceContentInfos[this.currentTab].page--;
    }
    this.commerceContentInfos[this.currentTab].commerceContents = [];
    this.findAllContents();
  }

  handleChangeRelative(evt: any, item: CommerceContent) {
    this.serviceHelperService.spinnerService.spin();
    return this.serviceHelperService.commerceService
      .updateRelationship({
        _id: item._id,
        relationShip: evt,
      })
      .then(() => this.serviceHelperService.spinnerService.unspin())
      .catch(() => this.serviceHelperService.spinnerService.unspin());
  }
}

@NgModule({
  declarations: [SavedReportsPage],
  imports: [
    CommonModule,

    RouterModule.forChild([
      {
        path: '',
        component: SavedReportsPage,
      },
    ]),

    MemberHeaderComponentModule,
    MemberFooterComponentModule,
    MemberPeopleSearchComponentModule,
    UiSwitchModule,
    NgSelectModule,
    ButtonsModule,
    AddNoteComponentModule,
  ],
  exports: [RouterModule],
})
export class SavedReportsPageModule {}
