import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, NgModule } from '@angular/core';

@Component({
  selector: 'app-modal-save-report',
  templateUrl: 'saveReport.component.html',
  styleUrls: ['saveReport.component.scss'],
})
export class SaveReportComponent implements OnInit {
  @Input() downloadPath: string;

  constructor() {}

  ngOnInit() {}

  goNext() {}
}

@NgModule({
  declarations: [SaveReportComponent],
  imports: [CommonModule],
  exports: [SaveReportComponent],
})
export class SaveReportComponentModule {}
