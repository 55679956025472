import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class MetaService {
    constructor(private meta: Meta, private title: Title) {}

    updateMetaTags(config: { title: string, description: string, image: string, url: string }) {
        this.meta.updateTag({ property: 'og:title', content: config.title });
        this.meta.updateTag({ property: 'og:description', content: config.description });
        this.meta.updateTag({ property: 'og:image', content: config.image });
        this.meta.updateTag({ property: 'og:url', content: config.url });
        this.meta.updateTag({ property: 'og:type', content: 'website' });
    }
}