import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {BaseComponent} from "../../../../clientCommon/components/BaseComponent";
import {ServiceHelperService} from "../../../../clientCommon/services/serviceHelper.service";
import {ProcessPeopleSearchMember} from "../processPeopleSearchMember";

@Component({
  templateUrl: 'process.component.html',
})

export class ProcessPhoneSearchMemberComponent extends ProcessPeopleSearchMember implements OnInit, OnDestroy {

  constructor(serviceHelperService: ServiceHelperService,
              activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
    this.pageCategory = BaseComponent.PAGE_CATOGORY.phoneSearch;
  }

  ngOnInit() {
    return this.onInit().then(() => {
      this.initDone = true;
    });
  }

  ngOnDestroy() {
    return super.onDestroy();
  }
}

