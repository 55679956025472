import { AfterViewInit, Component, ElementRef, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseDirective } from '../../../../clientCommon/directives/BaseDirective';
import { ServiceHelperService } from '../../../../clientCommon/services/serviceHelper.service';
import { AddressField, AddressUtils } from '../../../../common/utils/addressUtils';
import { LogUtils } from '../../../../common/utils/logUtils';
import { CommerceOrder } from '../../../../common/models/commerce/commerceOrder';
import { phoneUtils } from '../../../../common/utils/phoneUtils';
import { emailUtils } from '../../../../common/utils/emailUtils';
import { stringUtils } from '../../../../common/utils/stringUtils';
import { peopleSearchProductKeys } from '../../../../common/custom/peopleSearch/peopleSearchProductKeys';
import { SearchedPerson } from '../../../../common/custom/models/peopleSearch/searchedPerson';
import { customClientPaths } from '../../../../common/custom/customPathHelpers';
import { redirectHelper } from '../../../../clientCommon/helper/redirectHelper';
import { BaseComponent } from '../../../../clientCommon/components/BaseComponent';
import { environment } from '../../../../environments/environment';
import { User } from '../../../../common/models/user/user';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

class CustomProduct {
    public static ACTION = {
      redirect: 'redirect',
    };
  
    productType: string;
    name: string;
    action: string;
    value: string;
    forms: {
      [type: string]: any;
    }[] = [];
  
    public static CODE = {
      requestState: 'requestState',
    };
}

@Component({
    selector: 'app-member-keep-search',
    templateUrl: 'memberKeepSearch.component.html',
    styleUrls: ['./memberKeepSearch.component.scss'],
  })
  export class MemberKeepSearchComponent  extends BaseDirective implements OnInit {

    phoneUtils = phoneUtils;
    addressField: AddressField = AddressUtils.getAddressField('US');
    customerNumber = '';
    loaderEyeTimeout: number = environment.miniDetailsLoaderEyeTimeout; //2000; // loader animated Eye Timeout
  
    stateList = this.addressField;
    ageList = [];
    model: any = {
      fname: '',
      mname: '',
      lname: '',
      city: '',
      state: 'all',
      // age: "",
      phone: '',
      email: '',
      // relativeFName: "",
      // relativeLName: ""
    };
    relativesList = [];
  
    allStateObj = [{ code: 'all', name: 'All States' }];
    addressFieldWithAllStateOption;
    customProducts: CustomProduct[] = [];
    selectedCustomProductValues: any = {};
    productType: string;
    selectedPhotos = [];
    swiperIndex = 0;
    showAllRelatives = false;    
    stringUtils = stringUtils;

    constructor(serviceHelperService: ServiceHelperService, private router: Router, activatedRoute: ActivatedRoute) {
        super(serviceHelperService, activatedRoute);
    }

    ngOnInit() {
        //this.customerNumber = this.uxHelper.getValue('comp.brand.customer.phone');
        //this.showAllRelatives = this.isPdfPrintView;
        // creatting state list with all state option
        this.addressFieldWithAllStateOption = [
          ...this.allStateObj,
          ...this.addressField.getAdministrativeAreaOptionsArray(),
        ];
        if (!this.model.state) {
          this.model.state = 'all';
        }
        // creating ageList for dropdown
        let g = 18;
        while (g > 17 && g < 60) {
          this.ageList.push(g);
          g++;
        }
    }

    findOptionByKey(options, key) {
        let chosenOption;
        if (options) {
          options.some((option) => {
            if (option.key === key) {
              chosenOption = option;
              return true;
            }
          });
        }
    
        return chosenOption;
    }

    findCustomProduct(productType) {
        let foundCustomProduct: CustomProduct = null;
        this.customProducts.some((customProduct) => {
          if (customProduct.productType === productType) {
            foundCustomProduct = customProduct;
            return true;
          }
        });
    
        if (foundCustomProduct) {
          if (!this.selectedCustomProductValues[productType]) {
            this.selectedCustomProductValues[productType] = {};
          }
    
          if (foundCustomProduct.forms) {
            foundCustomProduct.forms.forEach((form) => {
              if (form.name && form.default && this.selectedCustomProductValues[productType][form.name] === undefined) {
                let value = form.default;
                if (value === CustomProduct.CODE.requestState) {
                  value = this.uxComposite.requestState;
                }
                this.selectedCustomProductValues[productType][form.name] = value;
                let option = this.findOptionByKey(form.options, value);
                if (option) {
                  foundCustomProduct.value = option.url;
                }
              }
            });
          }
        }
    
        return foundCustomProduct;
    }    

    onSearchAgain() {
        // copied from memberPeopleSearch.component.ts
        this.productType = peopleSearchProductKeys.nameSearch;
        let url = '/' + customClientPaths.memberNameSearchLoader;
        this.serviceHelperService.peopleSearchService.clear();
        let customProduct = this.findCustomProduct(this.productType);
        if (customProduct) {
          if (customProduct.action === CustomProduct.ACTION.redirect) {
            url = customProduct.value;
          }
        }
        if (this.model?.number) {
          this.model.number = this.model?.number.replace(/[()\s-]/g, '');
        }

        this.serviceHelperService.userInputService.userInput = {
          ...this.model,
        };

        this.serviceHelperService.userInputService.storeUserInput();
        return redirectHelper.redirect(this.serviceHelperService, url);
    }

    formatPhone(event) {
        event.target.value = this.model.number = this.phoneUtils.formatPhoneNumber(event.target.value);
    }    
  }

  @NgModule({
    declarations: [MemberKeepSearchComponent],
    imports: [CommonModule, FormsModule, NgSelectModule],
    exports: [MemberKeepSearchComponent],
  })
  export class MemberKeepSearchComponentModule {}